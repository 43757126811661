export default {
  labs: {
    label: 'Analyses',
    requests: {
      label: "Demandes d'analyse",
      new: "Nouvelle demande d'analyse",
      view: "Voir l'analyse",
      cancel: "Annuler l'analyse",
      complete: 'Analyse complète',
      error: {
        unableToRequest: "Impossible de créer une nouvelle demande d'analyse.",
        unableToComplete: "Impossible de compléter la demande d'analyse.",
        typeRequired: "Le type d'analyse est requis.",
        resultRequiredToComplete: "Le résultat est requis pour compléter l'analyse.",
      },
    },
    lab: {
      code: 'Code',
      status: 'Statut',
      for: 'Pour',
      type: 'Type',
      result: 'Résultat',
      notes: 'Notes',
      requestedOn: 'Demandé le',
      completedOn: 'Complété le',
      canceledOn: 'Annulé le',
    },
  },
}
