export default {
  actions: {
    edit: 'Редактировать',
    save: 'Сохранить',
    cancel: 'Отмена',
    new: 'Новый',
    list: 'Список',
    label: 'Действия',
    update: 'Обновить',
    complete: 'Закончить',
    delete: 'Удалить',
    search: 'Поиск',
    confirmDelete: 'Подтвердить удаление',
    next: 'Дальше',
    previous: 'Назад',
    page: 'Страница',
    add: 'Добавить',
    view: 'Просмотр',
  },
}
