export default {
  labs: {
    label: 'Laboratori',
    requests: {
      label: 'Richieste di laboratorio',
      new: 'Nuova richiesta di laboratorio',
      view: 'Visualizza richiesta',
      cancel: 'Annulla richiesta',
      complete: 'Completa',
      error: {
        unableToRequest: 'Impossibile creare la richiesta.',
        unableToComplete: 'Impossibile completare la richiesta.',
        typeRequired: 'La tipologia è richiesta.',
        resultRequiredToComplete: 'Il risultato è obbligatorio per completare la richiesta.',
      },
    },
    lab: {
      status: 'Stato',
      for: 'Per',
      type: 'Tipo',
      result: 'Risultato',
      notes: 'Note',
      requestedOn: 'Richiesta fatta il',
      completedOn: 'Completato il',
      canceledOn: 'Annullata il',
      patient: 'Paziente',
    },
  },
}
