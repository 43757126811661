export default {
  patients: {
    label: 'المرضى',
    viewPatients: 'عرض المرضى',
    noPatients: 'لا يوجد مرضى حاليا, قم بانشاء اول مريض حالا !',
    viewPatient: 'عرض المريض',
    editPatient: 'تغيير المريض',
    newPatient: 'مريض جديد',
  },
}
