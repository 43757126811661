export default {
  medications: {
    label: '薬',
    filterTitle: 'ステータスでフィルタリング',
    search: '薬を検索する',
    status: {
      draft: 'ドラフト',
      active: 'アクティブ',
      onHold: '保留',
      cancelled: 'キャンセル',
      completed: '完了',
      enteredInError: 'エラーで入力されました',
      stopped: '停止',
      unknown: 'わからない',
    },
    intent: {
      proposal: '提案',
      plan: '予定',
      order: '注文',
      originalOrder: '元の注文',
      reflexOrder: '反射神経の秩序',
      fillerOrder: 'フィラーオーダー',
      instanceOrder: 'インスタンスの順序',
      option: 'オプション',
    },
    priority: {
      routine: 'ルーチン',
      urgent: '緊急',
      asap: 'できるだけ速くに',
      stat: '統計',
    },
    filter: {
      all: 'すべてのステータス',
    },
    requests: {
      label: '薬のリクエスト',
      new: '薬をリクエストする',
      view: '薬を見る',
      cancel: '薬をキャンセルする',
      complete: '完全な投薬',
      error: {
        unableToRequest: '投薬リクエストを作成できません。',
        unableToComplete: '投薬要求を完了できません。',
        quantityRequired: '数量が必要です。',
        unitRequired: 'ユニットが必要です。',
      },
    },
    medication: {
      medication: '投薬',
      for: 'にとって',
      status: '状態',
      intent: '意図',
      priority: '優先',
      notes: 'ノート',
      quantity: '量',
      quantityValue: '値',
      quantityUnit: '単位',
      requestedOn: 'リクエスト済み',
      requestedBy: 'に要求された',
      completedOn: '完了',
      canceledOn: 'キャンセル日',
      patient: '患者',
    },
  },
}
