export default {
  medications: {
    label: 'Remédios',
    filterTitle: 'Filtrar por status',
    search: 'Pesquisar medicamentos',
    status: {
      draft: 'Esboço, projeto',
      active: 'Ativa',
      onHold: 'Em espera',
      cancelled: 'Cancelada',
      completed: 'Concluída',
      enteredInError: 'Erro inserido',
      stopped: 'Parada',
      unknown: 'Desconhecida',
    },
    intent: {
      proposal: 'Proposta',
      plan: 'Plano',
      order: 'Ordem',
      originalOrder: 'Pedido Original',
      reflexOrder: 'Ordem de Reflexo',
      fillerOrder: 'Pedido de Enchimento',
      instanceOrder: 'Pedido de Instância',
      option: 'Opção',
    },
    priority: {
      routine: 'Rotina',
      urgent: 'Urgente',
      asap: 'Assim que possível',
      stat: 'Estado',
    },
    filter: {
      all: 'Todos os status',
    },
    requests: {
      label: 'Pedidos de medicação',
      new: 'Solicitar Medicação',
      view: 'Ver medicação',
      cancel: 'Cancelar medicação',
      complete: 'Medicação Completa',
      error: {
        unableToRequest: 'Incapaz de criar um pedido de medicação.',
        unableToComplete: 'Incapaz de completar o pedido de medicação.',
        quantityRequired: 'A quantidade é obrigatória.',
        unitRequired: 'A unidade é necessária.',
      },
    },
    medication: {
      medication: 'Medicamento',
      for: 'Para',
      status: 'Status',
      intent: 'Intenção',
      priority: 'Prioridade',
      notes: 'Notas',
      quantity: 'Quantidade',
      quantityValue: 'Valor',
      quantityUnit: 'Unidade',
      requestedOn: 'Solicitado em',
      requestedBy: 'Requerido por',
      completedOn: 'Completo em',
      canceledOn: 'Cancelado em',
      patient: 'Paciente',
    },
  },
}
